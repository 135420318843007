const sortTableMixin = {
  data() {
    return {
      sortString: 'name,',
    };
  },
  methods: {
    // sortTable
    sortTable(colName, event, mainFunction) {
      // Check if colName is already in sort string
      const negativeName = '-' + colName;
      if (this.sortString.includes(colName) || this.sortString.includes(negativeName)) {
        // Check if exist with desc
        if (this.sortString.includes(negativeName)) {
          // Reset sort
          this.sortString = this.sortString.replace(negativeName, '');
          //Desc to asc
          //this.sortString = colName + this.sortString;
        } else {
          // Asc to desc
          this.sortString = this.sortString.replace(colName, '');
          this.sortString = negativeName + this.sortString;
        }
      } else {
        // If first time sort
        this.sortString = colName + this.sortString;
      }
      if (mainFunction) {
        mainFunction();
      } else {
        this.paramsData();
      }
    },
  },
};

export default sortTableMixin;
