<template>
  <b-pagination v-model="page" :total-rows="lastPage" per-page="1" first-number last-number ellipsis-class="ellipsis" />
</template>

<script>
import { BPagination } from 'bootstrap-vue';

export default {
  name: 'PaginateWrapper',
  components: { BPagination },
  props: {
    lastPage: {
      type: Number,
      default: undefined,
    },
    current: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    page: {
      get() {
        return this.current;
      },
      set(value) {
        this.$emit('pageClick', value);
      },
    },
  },
};
</script>

<style lang="sass">
@use '~/src/assets/sass/_variables' as *

.b-pagination
  margin-bottom: 0
  .page-item .page-link
    background-color: transparent
  .page-item:not(.disabled) .page-link
    color: #6c757d
    &:hover
      border-color: #dc3545 !important
  .page-item:not(.active, .disabled) .page-link,
  .page-item.ellipsis .page-link
    border-color: #6c757d
  .page-item.active .page-link
    color: #dc3545

[data-color-scheme="dark"]
  .b-pagination
    .page-item.disabled .page-link
      border-color: #6c757d
    .page-item:not(.active, .disabled) .page-link,
    .page-item.ellipsis .page-link
      border-color: #8790a4
      color: #8790a4
    .page-item.active .page-link
      color: $light-red
      border-color: $light-red
</style>
