<template>
  <form @submit.prevent="$emit('searchTable')">
    <b-input-group class="w-auto">
      <input v-model="textModel" type="search" class="form-control" :placeholder="$t('search.search')" />
      <b-input-group-append>
        <b-form-select v-if="showSelector" v-model="selectedSearchFieldType" :options="options" class="rounded-0" />
        <button class="btn btn-outline-danger ws-nowrap" type="submit">
          {{ $t('search.search') }}
        </button>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  model: {
    prop: 'text',
    event: 'change',
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    showSelector: {
      type: Boolean,
      required: false,
    },
    selectedSearchType: {
      type: String,
      default: 'name',
    },

    options: {
      type: Array,
      default: function () {
        return [
          { value: 'name', text: this.$i18n.t('table.name') },
          { value: 'id', text: 'ID' },
        ];
      },
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
    }),

    textModel: {
      get() {
        return this.text;
      },
      set(value) {
        this.$emit('change', value);
      },
    },

    selectedSearchFieldType: {
      get() {
        return this.selectedSearchType;
      },
      set(value) {
        this.$emit('update:selectedSearchType', value);
      },
    },
  },

  methods: {},
};
</script>

<style lang="sass" scoped></style>
