<template>
  <select v-model="selectModel" class="custom-select w-auto">
    <option class="option" value="50">50</option>
    <option class="option" value="100">100</option>
    <option class="option" value="300">300</option>
    <option class="option" value="500">500</option>
  </select>
</template>

<script>
export default {
  model: {
    prop: 'select',
    event: 'onSelect',
  },

  props: {
    select: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    selectModel: {
      get() {
        return this.select;
      },
      set(value) {
        this.$emit('onSelect', value);
      },
    },
  },
};
</script>
